@import "../../../assets/style/variable.module.scss";

.firmForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 40px;
  align-items: flex-start;

  .right {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    form {
      width: 100%;
      .inputWrapper {
        display: flex;
        width: 100%;
        gap: 40px;

        .column {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
      }
    }
  }
}

.logoUpload {
  width: 25%;
  background: white;
  border: 1px solid #d4d7e3;
  border-radius: 16px;
  padding: 50px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .iconWrapper {
    border: 1px dashed rgba($color: #d4d7e3, $alpha: 0.6);
    padding: 8px;
    box-sizing: border-box;
    border-radius: 50%;
    width: 128px;
    height: 128px;
    .icon {
      background: rgba($color: #d4d7e3, $alpha: 0.4);
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      span {
        color: #919eab;
        font-size: 12px;
      }
    }
  }

  .info {
    padding: 6px 12px;
    background: rgba($color: $--error-color, $alpha: 0.2);
    border-radius: 8px;
    color: $--error-color;
    margin-top: 24px;
    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}

.logoError {
  border: 1px solid #b3261e;
}

.firmPhotoUpload {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background: rgba($color: #919eab, $alpha: 0.08);
  border-radius: 8px;
  display: flex;

  .uploadArea {
    border: 1px dashed #cecece;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    padding: 46px 60px;
    box-sizing: border-box;
    border-radius: 12px;
    background: white;

    h5 {
      font-size: 18px;
      color: #1c252e;
      font-weight: 600;
      margin-top: 8px;
    }

    p {
      color: #637381;
      font-size: 14px;
      text-align: center;
      margin-top: 8px;
    }

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  .imageArea {
    display: flex;
    margin-left: 27px;
    gap: 12px;
    flex-wrap: wrap;

    .image {
      position: relative;

      button {
        position: absolute;
        right: 0;
        top: 0;
      }
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
        border-radius: 12px;
        border: 1px solid rgba($color: #919eab, $alpha: 0.2);
      }
    }
  }
}

.submit {
  background: $--primary-orange;
  color: white;
  font-size: 18px;
  padding: 17px 0;
  border-radius: 12px;
  width: 277px;
  margin-left: auto;
}

@media only screen and (max-width: 960px) {
  .firmForm {
    flex-direction: column;

    .submit {
      width: 100% !important;
      margin-left: unset !important;
    }

    .logoUpload {
      width: 100%;
    }

    .right {
      width: 100%;

      .inputWrapper {
        flex-direction: column;

        .column {
          width: 100% !important;
        }
      }
    }

    .firmPhotoUpload {
      flex-direction: column;
      gap: 20px;

      .imageArea {
        margin-left: 0;
      }
    }
  }
}
