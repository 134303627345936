@import "../../assets/style/variable.module.scss";

.register {
  width: 100vw;
  height: 100vh;
  padding: 4.9vh 11.8vh 10.4vh 11.8vh;
  box-sizing: border-box;
  background: $--background-color;

  .descriptionWrapper {
    max-width: 600px;
    margin: 0 auto;
  }

  .success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50vh;
    gap: 20px;

    svg {
      height: 150px;
      width: auto;
    }

    h5 {
      font-size: 24px;
      max-width: 500px;
      text-align: center;
    }
  }

  .contentWrapper {
    width: 100%;
    height: 65vh;
    margin-top: 4.5vh;
  }

  .buttonWrapper {
    display: flex;
    margin-top: 3.95vh;
    justify-content: space-between;
    width: 100%;
    position: relative;

    button {
      font-size: 18px;
      font-weight: 700;
      padding: 17px 95px;
      box-sizing: border-box;
      border-radius: 12px;
      border: 1px solid#d1d1d1;
      background: white;
      color: $--title-color;
    }

    .rightWrapper {
      display: flex;
      gap: 12px;

      .mainButton {
        border: unset;
        background: $--primary-blue;
        color: white;
        position: absolute;
        right: 0;
      }

      .skip {
        border: 1px solid $--primary-blue;
        color: $--primary-blue;
        padding: 17px 40px;
      }
    }
  }

  .buttonServiceWrapper {
    .rightWrapper {
      .mainButton {
        background: $--primary-orange;
      }

      .skip {
        border: 1px solid $--primary-orange;
        color: $--primary-orange;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .register {
    height: auto;
    padding: 20px 20px;
    max-height: unset;
    .contentWrapper {
      height: auto;
    }
    .buttonWrapper {
      height: auto;
      position: static;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 20px;

      .rightWrapper {
        .mainButton {
          position: static;
        }
      }
    }
  }
}
