@import "../../assets/style/variable.module.scss";

.forgotPassword {
  width: 100%;
  padding-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;

    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      .button {
        width: 100%;
        background: $--primary-blue;
        padding: 17px 0;
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 18px;
        color: white;
        font-weight: 600;
        margin-top: 24px;
        min-height: 61px;
        max-height: 61px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .inputWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        span {
          margin-bottom: 10px;
          display: block;
          color: $--error-color;
        }

        input {
        }
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;
      width: 100%;
      h1 {
        font-size: 42px;
        color: $--primary-blue;
        font-weight: 600;
        text-align: center;
      }

      p {
        font-size: 16px;
        color: $--title-color;
        text-align: center;
      }
    }

    .success {
      font-size: 20px;
      text-align: center;
      color: $--primary-blue;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    padding: 0 25px;
    box-sizing: border-box;
  }
}
