@import "../../assets/style/variable.module.scss";

.wrapper {
  display: flex;

  .photoCard {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 12px;
      color: #919eab;
      text-align: center;
      font-weight: 300;
      margin-top: 12px;
      margin-bottom: 24px;
    }

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    .imageWrapper {
      position: relative;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        object-position: center;
        border: 1px solid #d4d7e3;
        border-radius: 50%;
      }
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;

      button {
        position: relative;
        display: flex;
        align-items: center;
        padding: 6px 12px;
        border-radius: 8px;
        background: rgba($color: #00b8d9, $alpha: 0.08);

        color: $--primary-blue;
        font-size: 14px;
        font-weight: 700;
        gap: 8px;

        &:nth-child(2) {
          background: rgba($color: #ff5630, $alpha: 0.08);
          color: #ff5630;
        }
      }
    }
  }

  .formWrapper {
    width: 70%;

    form {
      width: 100%;

      .submit {
        padding: 17px 75px;
        color: white;
        background: $--primary-blue;
        font-size: 18px;
        font-weight: 600;
        border-radius: 12px;
        max-height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .submitSuccess {
        background: #34c759;
      }

      .input {
        width: 100%;
      }

      .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;

        .formSplit {
          display: flex;
          gap: 24px;
          width: 100%;

          .input {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
              color: #b3261e;
              display: block;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    flex-direction: column;
    .photoCard {
      width: 100%;
    }

    .formWrapper {
      width: 100%;
      margin-top: 25px;

      .submit {
        width: 100%;
        max-width: unset !important;
      }
    }
  }
}
