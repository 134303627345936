@import "../../assets/style/variable.module.scss";

.wrapper {
  margin-top: 150px;

  .sliderWrapper {
    min-height: 420px;
  }

  .card {
    background: #f5f2ed;
    min-height: 400px;
    border-radius: 10px;
    position: relative;
    opacity: 0.4;
    transition: all 0.2s ease;
    cursor: pointer;

    svg {
      position: absolute;
      top: 24px;
      left: 24px;

      path {
        fill: #858585;
        transition: all 0.2s ease;
      }
    }

    p {
      max-width: 230px;
      margin-top: 24px;
      max-height: 100px;
      min-height: 100px;
    }

    .content {
      position: absolute;
      bottom: 57px;
      left: 24px;
    }

    .title {
      display: flex;
      align-items: center;

      h5 {
        font-size: 36px;
        color: #413d45;
        line-height: 40px;
        margin-left: 12px;
        font-weight: 700;
        max-width: 170px;
      }

      img {
        width: 75px;
        height: 75px;
        object-fit: cover;
        object-position: center;
        border-radius: 20px;
      }
    }
  }

  .active {
    opacity: 1;
    margin-top: 12px;

    svg {
      path {
        fill: #ff7e1d;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    margin-top: 75px;
    box-sizing: border-box;

    span {
      display: block;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .sliderWrapper {
      overflow-x: hidden;
      max-width: 100vw;
    }
  }
}
