@import "../../assets/style/variable.module.scss";

.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  background: rgba($color: $--primary-blue, $alpha: 0.5);

  .card {
    background: white;
    width: 80vw;
    max-width: 700px;
    height: 90vh;
    position: relative;
    border-radius: 18px;
    overflow: hidden;

    .title {
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0;
      box-sizing: border-box;
      position: relative;

      .close {
        position: absolute;
        right: 24px;
        background: white;
        padding: 5px;
        border-radius: 50px;
      }

      h5 {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        gap: 12px;
      }
    }

    .buttonWrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #f5f5f5;
      padding: 18px 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      button {
        padding: 12px 24px;
        font-size: 14px;
        color: $--title-color;
        font-weight: 700;
        border: 1px solid #d0d5dd;
        background: white;
        border-radius: 8px;

        &:nth-child(2) {
          background: $--primary-blue;
          border: 1px solid transparent;
          color: white;
        }
      }
    }
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 24px;
    box-sizing: border-box;
    padding-top: 24px;
  }

  .inputContainer {
    width: 100%;
  }

  .inputSplit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .popup {
    .inputSplit {
      grid-template-columns: 1fr;
    }
  }
}
