@import "../../assets/style/variable.module.scss";

.wrapper {
  margin-top: -30px;
  display: flex;
}

.sideBar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dddddd;
  width: 30%;
  max-height: 77vh;
  overflow-y: scroll;
  padding: 20px 40px 20px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #dfe3e8;
  border-left: 1px solid #dfe3e8;

  h1 {
    font-size: 32px;
    color: $--title-color;
    font-weight: 700;
    margin-bottom: 16px;
  }

  label {
    border: 1px solid #dfe3e8;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    gap: 5px;
    border-radius: 8px;
    margin-bottom: 12px;

    svg {
      min-width: 18px;
      min-height: 18px;
    }
  }

  .userCardWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .userCard {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      padding: 12px 12px;
      cursor: pointer;
      position: relative;
      border-bottom: 1px solid #dfe3e8;

      .newMessage {
        background: $--primary-blue;
        color: white;
        font-size: 12px;
        border-radius: 50%;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid transparent;
        }

        .info {
          h5 {
            font-size: 14px;
            font-weight: 600;
            color: $--title-color;
          }

          p {
            font-size: 14px;
            color: #637381;
          }
        }
      }

      h6 {
        font-size: 12px;
        color: #a9abad;
      }
    }

    .selectedUser {
      background: rgba($color: $--primary-orange, $alpha: 0.06);

      .left {
        img {
          border: 1px solid $--primary-orange;
        }
        .info {
          h5 {
            color: $--primary-orange;
          }
        }
      }
    }
  }
}

.messageContent {
  max-height: 77vh;
  width: 70%;
  position: relative;

  .topBar {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    display: flex;

    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid #dfe3e8;
    border-right: 1px solid #dfe3e8;
    box-shadow: 0px 31px 24px rgba($color: #c2c2c2, $alpha: 0.08);

    .avatarWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .report {
      background: $--primary-orange;
      padding: 6px 12px;
      color: white;
      border-radius: 8px;
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }

    .info {
      h5 {
        font-size: 22px;
        color: $--title-color;
        font-weight: 700;
      }

      p {
        color: #637381;
        font-size: 14px;
        margin-top: -3px;
      }
    }
  }

  .content {
    .messageContainer {
      height: 60.6vh;
      overflow-y: scroll;
      padding: 24px;
      padding-bottom: 120px;
      box-sizing: border-box;
      border-right: 1px solid #dfe3e8;
      border-bottom: 1px solid #dfe3e8;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .message {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .info {
          display: flex;
          gap: 6px;
          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }

          p {
            display: flex;
            font-size: 12px;
            color: #637381;
            gap: 3px;
            align-items: center;
            font-weight: 500;

            .dot {
              width: 3px;
              height: 3px;
              background: #637381;
              border-radius: 50%;
              margin-bottom: 1px;
            }
          }
        }

        .messageContent {
          padding: 12px;
          box-sizing: border-box;
          background: #fffbf2;
          border-radius: 8px;
          max-width: 50vh;
          font-size: 16px;
          color: #212b36;

          p {
            word-break: break-word;
          }
        }
      }

      .sender {
        display: flex;
        align-items: flex-end;

        .messageContent {
          background: #f9f9f9;
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 24px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      padding: 0 24px;

      label {
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 24px;
        background: white;
        input {
          width: 100%;
          height: 100%;
          padding-left: 24px;
          background: transparent;
        }
      }

      button {
        padding: 16px 22px;
        border: 1px solid #e6e6e6;
        border-radius: 24px;
        background: white;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    flex-direction: column;
    .sideBar {
      width: 100%;
    }

    .messageContent {
      width: 100%;
      position: static;
      .topBar {
        border-left: 1px solid #dfe3e8;
      }
      .content {
        .messageContainer {
          border-left: 1px solid #dfe3e8;
          padding-bottom: 75px;
        }
        .bottom {
          position: fixed;
          left: 0;
          bottom: 0;
          background: white;
          padding: 20px 14px;
          border-top: 1px solid #dfe3e8;
        }
      }
    }
  }
}
