@import "../../assets/style/variable.module.scss";

.wrapper {
  width: 100%;
  min-height: 295px;
  background: #fafafa;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .top {
    display: flex;
    flex-direction: column;
    gap: 6px;

    h5 {
      font-weight: 800;
      font-size: 18px;
      color: $--title-color;
      max-width: 340px;
    }

    .userWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .rightWrapper {
        display: flex;
        gap: 10px;
      }

      .offered {
        display: flex;
        padding: 6px 12px;
        border-radius: 8px;
        gap: 8px;
        font-weight: 600;
        color: #34c759;
        background: rgba($color: #34c759, $alpha: 0.08);
      }

      .user {
        display: flex;
        align-items: center;
        gap: 6px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          border: 1px solid #e6e6e6;
        }

        p {
          font-size: 16px;
          color: $--title-color;
          font-weight: 500;
        }
      }

      a {
        display: flex;
        padding: 6px 12px;
        border-radius: 8px;
        gap: 8px;
        font-weight: 600;
        color: $--primary-orange;
        background: rgba($color: $--primary-orange, $alpha: 0.08);
      }
    }
  }

  .cardWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .card {
      width: calc(50% - 6px);
      background: #efefef;
      border: 1px solid #e6e6e6;
      padding: 6px 12px;
      box-sizing: border-box;
      border-radius: 8px;
      display: flex;
      gap: 6px;

      .info {
        p {
          font-size: 12px;
          color: $--subtitle-color;
        }

        h6 {
          font-size: 16px;
          color: $--primary-blue;
          font-weight: 700;
        }
      }

      &:nth-child(2) {
        .info {
          h6 {
            color: $--primary-orange;
          }
        }
      }
    }

    .fullCard {
      width: 100%;
      background: #efefef;
      border: 1px solid #e6e6e6;
      padding: 16px 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      svg {
        height: 20px;
        width: 30px;
        margin-right: 10px;
      }

      span {
        color: $--title-color;
        font-size: 14px;
        font-weight: 800;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    .cardWrapper {
      flex-wrap: wrap;

      .card {
        width: 100%;
      }
    }
  }
}
