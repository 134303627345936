@import "../../../assets/style/variable.module.scss";

.wrapper {
  display: flex;
  gap: 50px;

  .destination {
    width: 50%;

    h6 {
      font-size: 24px;
      font-weight: 600;
      color: $--title-color;
      margin-bottom: 12px;
    }

    .mapWrapper {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 24px;
    }

    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

.dashboardWrapper {
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .wrapper {
    .destination {
      h6 {
        font-size: 16px;
      }
    }
  }
}
