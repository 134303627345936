@import "../../assets/style/variable.module.scss";

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}

.cardWrapper {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 36px;
    box-sizing: border-box;
    border-radius: 12px;

    .textWrapper {
      h5 {
        color: #2c2c2c;
        font-size: 24px;
        font-weight: 700;
      }

      p {
        color: #4f4f4f;
        font-size: 14px;
        margin-top: 18px;
      }
    }

    a {
      padding: 11px 21px;
      color: #ffffff;
      border-radius: 8px;
      margin-top: 24px;
      cursor: pointer;
    }
  }

  .primary {
    background: #dfeeff;
    border: 1px solid #b2d6ff;

    a {
      background: #306fdf;
      border: 1px solid #699fff;
      cursor: pointer;
    }
  }

  .secondary {
    background: #fff5e0;
    border: 1px solid #ffe1a1;

    a {
      background: #f59a13;
      border: 1px solid #ffbc59;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .cardWrapper {
    max-width: 900px;
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    margin-top: 75px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .cardWrapper {
    grid-template-columns: 1fr;
    gap: 25px;
  }
}
