@import "../assets/style/variable.module.scss";

.wrapper {
  background: $--background-color;
  height: 100vh;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  gap: 24px;

  .shadow {
    display: none;
  }

  .sideBar {
    width: 220px;
    position: relative;

    .logo {
      height: 50px;

      img {
        height: 100%;
      }
    }

    .menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .item {
        display: flex;
        padding: 8px 12px;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;

        .itemWrapper {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        a {
          color: #637381;
        }
      }

      .activeItem {
        background: $--primary-orange;
        border-radius: 8px;

        .arrowButton {
          svg {
            transform: rotate(90deg);
            path {
              fill: white;
            }
          }
        }

        span {
          svg {
            path {
              fill: white;
            }
          }
        }

        a {
          color: white;
        }
      }
    }

    .subMenu {
      padding-left: 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .subItem {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 10px;

        a {
          color: #637381;
          min-width: 160px;
        }

        .circle {
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
          border-radius: 50%;
          border: 1px solid rgba($color: #919eab, $alpha: 0.4);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .activeCircle {
          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background: $--primary-orange;
            border-radius: 50%;
            position: absolute;
          }
        }
      }
    }

    .userCard {
      display: flex;
      background: white;
      padding: 8px 12px;
      box-sizing: border-box;
      border-radius: 8px;
      justify-content: space-between;
      position: absolute;
      bottom: 2px;
      width: 100%;

      .user {
        display: flex;
        align-items: center;
        gap: 6px;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #e6e6e6;
          object-fit: cover;
          object-position: center;
        }
        p {
          color: #637381;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .contentCard {
    background: white;
    border: 1px solid #dedede;
    width: 100%;
    border-radius: 24px;
    padding: 30px;
    box-sizing: border-box;
    overflow: scroll;

    .topBar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 30px;
      align-items: center;

      .title {
        display: flex;
        gap: 8px;

        svg {
          transform: rotate(180deg);
        }
      }

      .right {
        display: flex;
        gap: 12px;
      }
    }

    .contentWrapper {
      box-sizing: border-box;
      padding: 30px 0;
    }
  }

  .personalWrapper {
    .menu {
      .activeItem {
        background: $--primary-blue;
      }
    }

    .subMenu {
      .subItem {
        .activeCircle {
          &::before {
            background: $--primary-blue;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    height: auto;
    padding: 18px;
    min-height: 100vh;

    .shadow {
      width: 100vw;
      height: 100vh;
      background: rgba($color: #000000, $alpha: 0.5);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
    }

    .contentCard {
      height: auto;
      border-radius: 28px;

      .topBar {
        .right {
          flex-direction: row-reverse;
        }
      }
    }
    .sideBar {
      position: fixed;
      z-index: 3;
      background: #f5f5f5;
      height: 100%;
      left: 0;
      top: 0;
      padding: 20px;
      box-sizing: border-box;

      .userCard {
        width: calc(100% - 40px);
        bottom: 40px;
      }
    }
  }
}
