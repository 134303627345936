@import "../../../assets/style/variable.module.scss";

.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  position: relative;

  svg {
    position: absolute;
    bottom: 12px;
    right: 16px;
    pointer-events: none;
  }

  h5 {
    font-size: 16px;
    color: $--title-color;
  }

  &:focus-within {
    h5 {
      color: $--primary-orange;
      font-weight: 600;
    }
    select {
      border: 1px solid $--primary-orange;
    }
  }

  select {
    width: 100%;
    height: 48px;
    border: 1px solid #d4d7e3;
    border-radius: 12px;
    background: transparent;
    box-sizing: border-box;
    padding-left: 16px;
    font-size: 16px;
    background: white;

    &::placeholder {
      color: #d4d4d4;
    }
  }
}
