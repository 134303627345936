@import "../../assets/style/variable.module.scss";

.login {
  width: 100vw;
  height: 100vh;
  background: $--background-color;
  box-sizing: border-box;

  .wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 40px;
    height: 100%;
    max-height: 100vh;
    gap: 40px;

    .imageCard {
      height: 100%;
      width: 35%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 24px;
        border: 1px solid #e9e9e9;
        box-shadow: 0px 0px 70px 0px rgba($color: #c9c9c9, $alpha: 0.5);
      }
    }

    .card {
      background: white;
      width: 65%;
      height: 100%;
      box-shadow: 0px 0px 70px 0px rgba($color: #c9c9c9, $alpha: 0.5);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .copyright {
        color: #acacac;
        font-size: 12px;
        position: absolute;
        bottom: 24px;
      }

      .logo {
        height: 45px;
        position: absolute;
        left: 24px;
        top: 24px;

        img {
          height: 100%;
        }
      }

      .content {
        width: 100%;
        padding: 0 12vw;
        box-sizing: border-box;

        h1 {
          font-size: 36px;
          color: $--title-color;
          font-weight: 800;
        }
        p {
          font-size: 18px;
          color: $--title-color;
          font-weight: 400;
          margin-top: 4px;
          margin-bottom: 24px;

          a {
            color: $--primary-orange;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .inputWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
      margin-bottom: 10px;
      display: block;
      color: $--error-color;

      span {
        color: $--primary-blue;

        button {
          text-decoration: underline;
        }
      }
    }

    input {
    }
  }
}

.forgotPassword {
  color: $--primary-blue;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-top: 24px;
}

.button {
  width: 100%;
  background: $--primary-blue;
  padding: 17px 0;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 18px;
  color: white;
  font-weight: 600;
  margin-top: 24px;
  min-height: 61px;
  max-height: 61px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 960px) {
  .login {
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
      flex-direction: column;
      padding: 25px;

      .card {
        width: 100%;
        min-height: 90vh;

        .content {
          padding: 0 24px;
          form {
            .inputWrapper {
              display: flex;
              flex-direction: column;
              gap: 20px;
            }
          }
        }
      }
      .imageCard {
        display: none;
      }
    }
  }
}
