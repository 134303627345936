@import "../../assets/style/variable.module.scss";

.wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 220px;
  box-sizing: border-box;
}

.hero {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 1200px;

  .left {
    h1 {
      font-size: 72px;
      color: #434343;
      line-height: 85px;

      span {
        color: #282828;
        font-weight: 800;
      }
    }

    p {
      font-size: 18px;
      line-height: 25px;
      max-width: 550px;
      margin-top: 12px;
      color: #4f4f4f;
    }

    .buttonWrapper {
      display: flex;
      margin-top: 60px;
      gap: 12px;

      a {
        font-weight: 700;
        padding: 11px 28px;
        box-sizing: border-box;
        border-radius: 8px;
      }

      .primary {
        background: $--primary-blue;
        color: white;
        border: 1px solid #699fff;
      }
      .secondary {
        background: $--primary-orange;
        color: white;
        border: 1px solid #ffbc59;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    .hero {
      max-width: 900px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    padding: 0 20px;
    box-sizing: border-box;
    padding-top: 180px;

    .hero {
      flex-direction: column-reverse;
      gap: 20px;
      .left {
        h1 {
          text-align: center;
          font-size: 42px;
          line-height: 52px;
        }

        p {
          text-align: center;
        }

        .buttonWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 30px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 300px;
        }
      }
    }
  }
}
