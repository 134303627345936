@import "../../../assets/style/variable.module.scss";

.topbar {
  display: flex;
  justify-content: space-between;

  .logo {
    width: 170px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .stepperWrapper {
    display: flex;
    gap: 12px;

    .stepper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80px;
      height: 10px;
      position: relative;

      h6 {
        position: absolute;
        white-space: nowrap;
        left: 0;
        bottom: -32px;
        display: none;
        font-size: 18px;
        color: #667085;
      }
      .stepBar {
        width: 80px;
        height: 10px;
        background: #eaecf5;
        border-radius: 4px;
      }
    }

    .active {
      h6 {
        display: block;
      }

      .stepBar {
        background: $--primary-blue;
      }
    }
  }
}

.service {
  .stepperWrapper {
    .stepper {
      .stepBar {
        background: rgba($color: $--primary-orange, $alpha: 0.1);
      }
    }

    .active {
      .stepBar {
        background: $--primary-orange;
      }
    }

    .complete {
      .stepBar {
        background: $--primary-orange;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .topbar {
    align-items: center;
    .logo {
      width: 80px;
      min-width: 80px;
      height: auto;
    }
    .stepperWrapper {
      .stepper {
        width: 20px;

        h6 {
          font-size: 14px;
          bottom: -24px;
        }

        .stepBar {
          width: 100%;
        }
      }
    }
  }
}
