@import "../../assets/style/variable.module.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
}

.cardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1200px;
  gap: 24px;

  .card {
    display: flex;
    flex-direction: column;

    img {
      height: 290px;
      object-fit: cover;
      object-position: center;
    }

    h5 {
      margin-top: 40px;
      text-align: center;
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 700;
    }
    p {
      margin-top: 12px;
      text-align: center;
      color: #787878;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .cardWrapper {
    max-width: 900px;
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    margin-top: 75px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .cardWrapper {
    grid-template-columns: 1fr;

    .card {
      img {
        height: 200px;
        object-fit: contain;
      }
      h5 {
        margin-top: 20px;
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
