@import "../../assets/style/variable.module.scss";

.wrapper {
  margin-top: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    max-width: 1200px;
    gap: 24px;
    box-sizing: border-box;

    .card {
      background: white;
      border: 1px solid #dbdbdb;
      padding: 20px;
      padding-top: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      border-radius: 12px;
      transition: all 0.3s ease;

      .iconWrapper {
        position: absolute;
        right: -15px;
        top: -30px;
        width: 100px;
        height: 100px;
        border: 1px solid #dbdbdb;
        border-radius: 50%;
        background: white;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
      }

      &:hover {
        border: 1px solid #f3c785;
        box-shadow: 0px 0px 40px 0px rgba($color: #ffc46c, $alpha: 0.25);

        .iconWrapper {
          border: 1px solid #f3c785;
        }
      }

      h3 {
        font-size: 24px;
        color: #2b2b2b;
        font-weight: 700;
        line-height: 28px;
        max-width: 180px;
      }

      p {
        color: #787878;
        font-size: 16px;
        line-height: 22px;
        margin-top: 12px;
      }
    }

    .active {
      border: 1px solid #f3c785;
      box-shadow: 0px 0px 40px 0px rgba($color: #ffc46c, $alpha: 0.25);

      .iconWrapper {
        border: 1px solid #f3c785;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    .cardWrapper {
      max-width: 900px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    margin-top: 75px;
    padding: 0 20px;
    box-sizing: border-box;

    .cardWrapper {
      grid-template-columns: 1fr;
      gap: 50px;

      .card {
        h3 {
          max-width: 220px;
        }

        .iconWrapper {
          right: 0;
        }
      }
    }
  }
}
