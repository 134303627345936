@import "../../assets/style/variable.module.scss";

.wrapper {
  margin-top: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  border: 1px solid #d3d3d3;
  background: #eeeeee;
  border-radius: 25px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 70px 0;
  padding-right: 30px;
  box-sizing: border-box;

  .phone {
    position: absolute;
    height: 40vw;
    left: -5vw;
  }

  .content {
    width: 50%;

    .title {
      position: relative;

      img {
        position: absolute;
        height: 67px;
        left: -70px;
      }
      h3 {
        color: #2c2c2c;
        font-size: 46px;
        font-weight: 700;
      }
    }

    p {
      color: #646464;
      font-size: 16px;
      line-height: 166%;
      max-width: 460px;
      margin-top: 18px;
    }

    .buttonWrapper {
      display: flex;
      gap: 12px;
      box-sizing: border-box;
      margin-top: 60px;

      a {
        height: 70px;
        img {
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    .card {
      max-width: 900px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    margin-top: 170px;
    padding: 0 20px;
    box-sizing: border-box;

    .card {
      flex-direction: column;
      padding-top: 300px;
      padding-bottom: 40px;
      width: 100%;

      .phone {
        left: unset;
        height: unset;
        width: 90%;
        top: -150px;
      }

      .content {
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;

        .title {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            position: static;
            width: 30px;
            height: auto;
          }
          h3 {
            font-size: 26px;
          }
        }

        p {
          font-size: 14px;
          line-height: 144%;
        }

        .buttonWrapper {
          margin-top: 30px;

          a {
            width: 100%;
            height: auto;
            img {
              object-fit: contain;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
