@import "../../assets/style/variable.module.scss";

.footer {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .imageWrapper {
    width: 100%;
    height: 600px;
    position: absolute;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &::after {
      content: "";
      width: 100%;
      height: 200px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 18%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      top: 0;
    }
    &::before {
      content: "";
      width: 100%;
      height: 400px;
      background: linear-gradient(
        180deg,
        rgba(245, 154, 19, 0) 0%,
        rgba(245, 154, 19, 1) 80%
      );
      position: absolute;
      bottom: 0;
    }
  }

  .contentWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    max-width: 1200px;
    height: 600px;
    padding-bottom: 45px;
    box-sizing: border-box;

    .content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: 50px;

        img {
          height: 100%;
        }
      }

      ul {
        display: flex;
        align-items: center;
        gap: 24px;

        li {
          a {
            color: white;
            font-size: 16px;
          }
        }
      }

      .socialMedia {
        display: flex;
        gap: 12px;

        a {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          border: 1px solid white;
          border-radius: 50%;
        }
      }
    }
  }

  .bottomWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212121;
    padding: 15px 0;

    .bottom {
      width: 100%;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        color: white;
        font-size: 12px;
        font-weight: 400;
      }

      ul {
        display: flex;
        align-items: center;
        gap: 54px;
        li {
          position: relative;
          display: flex;
          align-items: center;

          &:not(:last-child) {
            &::after {
              content: "";
              width: 6px;
              height: 6px;
              background: #d9d9d9;
              position: absolute;
              right: -30px;
              border-radius: 50%;
            }
          }
          a {
            color: white;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.grey {
  margin-top: 0;
  .imageWrapper {
    &::after {
      content: "";
      width: 100%;
      height: 200px;
      background: linear-gradient(
        180deg,
        rgba(245, 245, 245, 1) 18%,
        rgba(245, 245, 245, 0) 100%
      );
      position: absolute;
      top: 0;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    .content {
      max-width: 900px;
    }

    .bottomWrapper {
      .bottom {
        max-width: 900px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .footer {
    margin-top: 75px;

    .imageWrapper {
      height: 300px;

      &::after {
        height: 100px;
        top: -1px;
      }

      &::before {
        height: 300px;
      }
    }
    .contentWrapper {
      height: 300px;

      .content {
        flex-direction: column;
        gap: 30px;
      }
    }

    .bottomWrapper {
      .bottom {
        flex-direction: column;
        padding: 0 20px;
        box-sizing: border-box;

        ul {
          flex-direction: column;
          gap: 10px;
          margin-top: 20px;

          li {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .grey {
    margin-top: 0;
  }
}
