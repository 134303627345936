@import "../../assets/style/variable.module.scss";

.videoWrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 80vh;
  border-radius: 0 0 120px 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: auto;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    button {
      position: absolute;
    }
  }
}

.zIndex {
  position: relative;
  z-index: 10000;
  background: $--primary-orange;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;

  .illustrationWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;

    h1 {
      margin-bottom: 60px;
      font-size: 42px;
      color: $--primary-orange;
      font-weight: 700;
    }
    .cardWrapper {
      display: flex;
      flex-direction: column;
      gap: 60px;

      .card {
        display: flex;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        img {
          width: 50%;
          object-fit: contain;
        }

        .textWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;
          justify-content: center;

          h5 {
            font-size: 36px;
            font-weight: 600;
            color: $--title-color;
          }

          p {
            font-size: 26px;
            font-weight: 500;
            text-align: center;
            max-width: 400px;
            margin-top: 18px;
            line-height: 36px;

            color: $--subtitle-color;
          }
        }
      }
    }
  }

  .bottomPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      object-position: 0 -100px;
      border-radius: 24px;
    }

    h3 {
      font-size: 36px;
      font-weight: 600;
      margin-top: 50px;
      color: $--title-color;
    }

    p {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      margin-top: 24px;
      color: $--subtitle-color;
    }
  }
}

@media only screen and (max-width: 960px) {
  .videoWrapper {
    height: 45vh;
    background: $--primary-orange;
    border-radius: 0 0 50px 50px;
    display: flex;

    video {
      width: 100%;
      height: auto;
    }

    .cover {
      img {
        object-position: -250px 0;
      }
    }
  }

  .wrapper {
    margin-top: 50px;
    padding: 0 25px;
    .illustrationWrapper {
      margin-top: 0;

      h1 {
        text-align: center;
      }

      .cardWrapper {
        .card {
          flex-direction: column;

          img {
            width: 100%;
          }

          .textWrapper {
            width: 100%;
            margin-top: 20px;

            h5 {
              text-align: center;
              font-size: 26px;
            }

            p {
              font-size: 18px;
              line-height: 26px;
            }
          }

          &:nth-child(even) {
            flex-direction: column;
          }
        }
      }

      .bottomPart {
        margin-top: 50px;

        img {
          height: auto;
          width: 100%;
          object-position: center;
        }

        h3 {
          font-size: 28px;
          text-align: center;
          margin-top: 25px;
        }

        p {
          font-size: 18px;
          margin-top: 15px;
        }
      }
    }
  }
}
