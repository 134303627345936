@import "../../../assets/style/variable.module.scss";

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 50px;
  box-sizing: border-box;

  .card {
    width: 50%;
    background: white;
    border-radius: 24px;
    padding: 30px 40px 40px 40px;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;

    .top {
      display: flex;
      gap: 12px;
      align-items: center;

      .circle {
        width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px;
        border-radius: 50%;
        border: 1px solid #d1d1d1;
      }

      h5 {
        font-size: 26px;
        color: $--title-color;
        font-weight: 700;
      }
    }

    .imageWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 40vh;
      }
    }
    .textWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
        font-size: 16px;
        max-width: 520px;
        color: $--subtitle-color;
        font-weight: 400;
      }
    }
  }

  .activeCustomer {
    background: rgba($color: $--primary-blue, $alpha: 0.2);
    border: 1px solid $--primary-blue;

    .top {
      .circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $--primary-blue;
        border: unset;

        &::before {
          content: "";
          position: absolute;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }

  .activeService {
    background: rgba($color: $--primary-orange, $alpha: 0.2);
    border: 1px solid $--primary-orange;

    .top {
      .circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $--primary-orange;
        border: unset;

        &::before {
          content: "";
          position: absolute;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    flex-direction: column;
    .card {
      width: 100%;
      .imageWrapper {
        img {
          height: 100px;
        }
      }
    }
  }
}
