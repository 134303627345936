@import "../../assets/style/variable.module.scss";

.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  background: rgba($color: $--primary-blue, $alpha: 0.5);

  .card {
    background: white;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 5px;

    h5 {
      font-size: 18px;
      font-weight: 600;
    }

    .buttonWrapper {
      display: flex;
      gap: 20px;
      align-items: center;

      button {
        width: 100%;
        background: $--primary-orange;
        color: white;
        padding: 12px 0;
        font-size: 18px;
        font-weight: 700;
        border-radius: 12px;
        margin-top: 24px;

        &:nth-child(1) {
          background: white;
          border: 1px solid #d0d5dd;
          color: $--title-color;
        }
      }
    }
  }
}

.deleteAccount {
  .card {
    border: 1px solid #b3261e;
    max-width: 600px;

    .content {
      margin-top: 12px;
      .input {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        position: relative;

        h5 {
          font-size: 16px;
          color: $--title-color;
        }

        &:focus-within {
          h5 {
            color: $--primary-orange;
            font-weight: 600;
          }
          input {
            border: 1px solid $--primary-orange;
          }
        }

        .inputWrapper {
          width: 100%;
          position: relative;
          max-height: 48px;
        }
        button {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
        }

        input {
          width: 100%;
          height: 48px;
          border: 1px solid #d4d7e3;
          border-radius: 12px;
          background: transparent;
          box-sizing: border-box;
          padding-left: 16px;
          font-size: 16px;
          background: white;

          &::placeholder {
            color: #d4d4d4;
          }
        }
      }

      .selectInput {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        position: relative;
        margin-top: 12px;
        margin-bottom: 12px;

        svg {
          position: absolute;
          bottom: 12px;
          right: 16px;
          pointer-events: none;
        }

        h5 {
          font-size: 16px;
          color: $--title-color;
        }

        &:focus-within {
          h5 {
            color: $--primary-orange;
            font-weight: 600;
          }
          select {
            border: 1px solid $--primary-orange;
          }
        }

        select {
          width: 100%;
          height: 48px;
          border: 1px solid #d4d7e3;
          border-radius: 12px;
          background: transparent;
          box-sizing: border-box;
          padding-left: 16px;
          font-size: 16px;
          background: white;

          &::placeholder {
            color: #d4d4d4;
          }
        }
      }

      .deleteText {
        color: #b3261e;
        margin-bottom: 12px;
      }
    }

    .buttonWrapper {
      button {
        background: #b3261e;
      }
    }
  }
}
