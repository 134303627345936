@import "../../assets/style/variable.module.scss";

.wrapper {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .seeAll {
    background: $--primary-blue;
    margin-top: 120px;
    display: flex;

    color: white;
    gap: 8px;
    align-items: center;
    padding: 11px 90px;
    border-radius: 8px;
    font-weight: 700;
  }
  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1200px;
    gap: 32px;

    .card {
      position: relative;
      display: flex;
      justify-content: center;

      img {
        height: 256px;
        width: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 6px;
      }
      .textWrapper {
        background: #ffffff;
        border: 1px solid #f5f5f5;
        position: absolute;
        width: calc(100% - 50px);
        bottom: -80px;
        border-radius: 6px;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 0px 40px 0px rgba($color: #000000, $alpha: 0.05);

        h5 {
          text-align: center;
          font-size: 16px;
          color: $--primary-blue;
          font-weight: 700;
        }

        p {
          text-align: center;
          margin-top: 12px;
          font-size: 14px;
          line-height: 150%;
          min-height: 100px;
          max-height: 100px;
          color: #1f2937;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    .cardWrapper {
      max-width: 900px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 75px;

    .cardWrapper {
      grid-template-columns: 1fr;
      gap: 70px;

      .card {
        .textWrapper {
          bottom: -40px;
        }
      }
    }

    .seeAll {
      margin-top: 80px;
    }
  }
}
