@import "../../../assets/style/variable.module.scss";

.selectionWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;
  height: 100%;

  .selection {
    width: 100%;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: space-between;
    align-items: flex-end;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    .info {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      width: 100%;
      .circle {
        width: 24px;
        height: 24px;
        border: 1px solid #d1d1d1;
        border-radius: 50%;
      }

      .textWrapper {
        display: flex;
        flex-direction: column;

        h5 {
          font-size: 26px;
          color: $--title-color;
          line-height: 30px;
          margin-top: -2px;
          font-weight: 700;
        }

        p {
          max-width: 250px;
          margin-top: 12px;
        }
      }
    }

    svg {
      height: 160px;
      width: auto;
      position: absolute;
      right: 24px;
      bottom: 24px;
    }
  }

  .selected {
    background: rgba($color: #3170de, $alpha: 0.06);
    border: 1px solid $--primary-blue;

    .info {
      .circle {
        position: relative;
        background: $--primary-blue;
        border: unset;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }
}

.heightWrapper {
  height: 100%;

  .selection {
    align-items: center;
    height: 25vh;
    svg {
      height: 7vh;
    }
  }
}

@media only screen and (max-width: 960px) {
  .selectionWrapper {
    grid-template-columns: 1fr;

    .selection {
      svg {
        position: static;

        height: 50px;
      }
    }
  }
}
