@import "../../assets/style/variable.module.scss";

.container {
  position: relative;
}

.wrapper {
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  background: #efefef;
  display: flex;
  align-items: center;
  padding: 0 12px 0 8px;
  min-height: 48px;
  cursor: pointer;

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 1px solid #e6e6e6;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .title {
    margin-right: 24px;
    display: flex;
    flex-direction: column;

    h5 {
      font-size: 16px;
      color: $--title-color;
      margin-bottom: -2px;
    }
    h6 {
      font-size: 12px;
      color: $--subtitle-color;
      margin-top: -2px;
    }
  }

  .arrow {
    width: 20px;
    height: 20px;
    background: rgba($color: $--primary-orange, $alpha: 0.2);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.popup {
  position: absolute;
  width: 420px;
  top: 55px;
  right: 0px;
  background: white;
  border: 1px solid #dadada;
  border-radius: 24px;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 0px 35px rgba($color: #c2c2c2, $alpha: 0.4);
  padding: 20px;

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .user {
      display: flex;
      align-items: center;
      gap: 12px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }

      .title {
        h5 {
          color: $--title-color;
          font-size: 14px;
        }

        p {
          color: #637381;
          font-size: 14px;
        }
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;

    li {
      a {
        color: #637381;
        font-weight: 500;
      }
    }

    .signOut {
      border-top: 1px dashed #dadada;
      width: 100%;
      padding-top: 14px;

      button {
        color: #ff5630;
      }
    }
  }
}

.personalWrapper {
  .wrapper {
    .arrow {
      background: rgba($color: $--primary-blue, $alpha: 0.2);

      svg {
        path {
          fill: $--primary-blue;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .container {
    position: static;
    .wrapper {
      .title {
        display: none;
      }
    }
  }

  .popup {
    top: 120px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
}
