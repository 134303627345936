@import "../../assets/style/variable.module.scss";

.wrapper {
  width: 100%;
  padding-top: 200px;
  background: $--background-color;
  display: flex;
  justify-content: center;

  .card {
    background: white;
    max-width: 1200px;
    border-radius: 24px;
    box-sizing: border-box;
    padding: 70px;
    margin-bottom: 75px;

    .section {
      h5 {
        font-size: 24px;
        color: $--title-color;
        margin-bottom: 25px;
        font-weight: 600;
      }

      p {
        margin-bottom: 25px;
        color: $--subtitle-color;
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    padding: 0 25px;
    padding-top: 150px;
    box-sizing: border-box;

    .card {
      padding: 40px;

      .section {
        h5 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
