@import "../../../assets/style/variable.module.scss";

.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  position: relative;

  h5 {
    font-size: 16px;
    color: $--title-color;
  }

  &:focus-within {
    h5 {
      color: $--primary-orange;
      font-weight: 600;
    }
    .wrapper {
      border: 1px solid $--primary-orange;
    }
  }

  .wrapper {
    width: 100%;
    height: 48px;
    border: 1px solid #d4d7e3;
    border-radius: 12px;
    background: white;
    box-sizing: border-box;
    padding-left: 16px;
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }

  .hiddenInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
  }
}
