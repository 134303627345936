@import "../../assets/style/variable.module.scss";

.container {
  background: $--background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 150px;

  .wrapper {
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    display: flex;
    align-items: center;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 32px;
        color: $--title-color;
        font-weight: 700;
      }

      .inputWrapper {
        display: flex;
        align-items: center;
        background: white;
        width: 400px;
        height: 50px;
        padding: 13px;
        box-sizing: border-box;
        border-radius: 24px;
        border: 1px solid #ebebeb;
        margin-top: 24px;

        svg {
          width: 22px;
          height: 22px;
        }

        input {
          width: 90%;
          background: transparent;
          font-size: 14px;
          padding-left: 10px;
          color: $--subtitle-color;

          &:focus {
            border: none !important;
          }
        }
      }
    }

    .cardWrapper {
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      .card {
        width: 100%;
        background: #efefef;
        border: 1px solid #e6e6e6;
        border-radius: 12px;
        padding: 24px;
        box-sizing: border-box;
        transition: all 0.3s ease;

        .titleWrapper {
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          svg {
            transition: all 0.3s ease;
          }

          h5 {
            font-size: 20px;
            color: $--title-color;
            font-weight: 700;
          }
        }

        p {
          transition: all 0.3s ease;
          height: 0;
          opacity: 0;
          pointer-events: none;
        }
      }

      .open {
        background: white;
        border: 1px solid #ff9a00;

        .titleWrapper {
          border-bottom: 1px solid #e9e9e9;
          padding-bottom: 12px;
          svg {
            transform: rotate(180deg);
          }
        }
        p {
          margin-top: 12px;

          opacity: 1;
          height: auto;
          pointer-events: all;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .container {
    padding-top: 150px;
    padding-bottom: 50px;
    .wrapper {
      padding: 0 25px;
      .top {
        h1 {
          text-align: center;
          font-size: 28px;
        }
      }
      .cardWrapper {
        margin-top: 24px;
      }
    }
  }
}
