@import "../../../assets/style/variable.module.scss";

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  h1 {
    font-size: 42px;
    color: $--title-color;
    font-weight: 700;
  }

  .cardWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 90%;
    width: 100%;
    gap: 24px;

    .card {
      width: calc(33% - 12px);
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46%;
      border-radius: 24px;
      border: 1px solid #d1d1d1;
      position: relative;
      cursor: pointer;

      h5 {
        font-size: 22px;
        color: $--title-color;
        font-weight: 700;
        text-align: center;
        max-width: 70%;
      }

      .circle {
        position: absolute;
        left: 24px;
        top: 24px;
        border: 1px solid #d1d1d1;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }

    .active {
      background: rgba($color: #3170de, $alpha: 0.06);
      border: 1px solid $--primary-blue;
      .circle {
        background: $--primary-blue;
        border: unset;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }
}

.dashboardWrapper {
  h1 {
    font-size: 24px;
  }

  .cardWrapper {
    .card {
      padding: 15px 0;

      .circle {
        width: 24px;
        height: 24px;
        top: 20px;
      }
      h5 {
        font-size: 24px;
      }
    }

    .active {
      .circle {
        &::before {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    h1 {
      font-size: 18px;
    }

    .cardWrapper {
      flex-direction: column;
      flex-wrap: nowrap;

      .card {
        width: 100%;
        padding: 20px 0;
        display: flex;
        gap: 20px;
        align-items: center;

        .circle {
          position: static;
          width: 25px;
          height: 25px;
          position: absolute;
          left: 20px;

          &::before {
            content: "";
            position: absolute;
            width: 12.5px;
            height: 12.5px;
            border-radius: 50%;
            background: white;
          }
        }
        h5 {
          font-size: 24px;
        }
      }
    }
  }
}
