@import "../../assets/style/variable.module.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .comment {
    border: 1px solid #e6e6e6;
    padding: 25px 100px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    h3 {
      color: $--primary-orange;
      font-size: 36px;
      font-weight: 700;

      span {
        font-size: 22px;
        color: $--subtitle-color;
        font-weight: 300;
      }
    }

    h4 {
      color: $--subtitle-color;
      font-size: 16px;
      font-weight: 300;
      margin-top: 5px;
    }
  }

  .commentWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    width: 100%;
  }

  .commentCard {
    width: 100%;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fafafa;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      padding: 24px;
      border-radius: 12px 12px 0 0;

      .info {
        display: flex;
        gap: 24px;

        img {
          width: 50px;
          height: 50px;
          border: 1px solid #e6e6e6;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }

        .title {
          h5 {
            font-size: 16px;
            color: $--title-color;
            font-weight: 700;
          }

          h6 {
            font-size: 16px;
            color: $--subtitle-color;
            font-weight: 500;
          }
        }
      }

      button {
        color: $--subtitle-color;
        font-size: 14px;
        font-weight: 800;
        padding: 6px 12px;
        background: #e7e7e7;
        border-radius: 8px;
      }
    }

    .content {
      border-radius: 0 0 12px 12px;
      border: 1px solid #e6e6e6;
      margin-top: -1px;
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      gap: 24px;
      align-items: center;

      .star {
        width: 100px;
        height: 100px;
        min-width: 100px;
        background: #fafafa;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 0 12px;
        box-sizing: border-box;

        h6 {
          font-size: 22px;
          color: $--title-color;
          font-weight: 700;
        }

        .starWrapper {
          display: flex;
          gap: 3px;
        }
      }

      p {
        font-size: 18px;
        color: $--subtitle-color;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    .comment {
      padding: 40px 0;
      width: 100%;
      h3 {
        font-size: 24px;
      }
    }

    .commentWrapper {
      .commentCard {
        .content {
          flex-direction: column;
        }
      }
    }
  }
}
