@import "../../assets/style/variable.module.scss";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  .content {
    width: 70%;
  }

  .titleWrapper {
    margin-bottom: 25px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      color: $--primary-orange;
    }
  }

  .cardWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .barWrapper {
  }

  .filterBar {
    display: flex;
    flex-direction: column;
    gap: 25px;
    border-right: 1px solid #e9e9e9;
    padding-right: 40px;

    .resetButton {
      width: 100%;
      border: 1px solid $--primary-orange;
      padding: 12px 0;
      font-size: 16px;
      font-weight: 700;
      color: $--primary-orange;
      border-radius: 5px;
      background: rgba($color: $--primary-orange, $alpha: 0.02);
    }

    .container {
      h5 {
        font-size: 14px;
        font-weight: 600;
      }

      .selectionWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 15px;

        .selection {
          display: flex;
          align-items: center;
          border: 1px solid #e6e6e6;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 5px;
          cursor: pointer;
          .square {
            width: 15px;
            height: 15px;
            min-width: 15px;
            min-height: 15px;
            border: 1px solid #d1d1d1;
            margin-right: 10px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
          svg {
            height: 20px;
            width: auto;
          }
          span {
            width: 30px;
          }
          p {
            font-size: 16px;
            display: flex;
            gap: 5px;
            align-items: center;
            font-weight: 500;
          }
        }

        .activeSelection {
          border: 1px solid $--primary-orange;
          background: rgba($color: $--primary-orange, $alpha: 0.02);
          .square {
            border: 1px solid $--primary-orange;
            &::before {
              content: "";
              width: 7px;
              height: 7px;
              position: absolute;
              background: $--primary-orange;
              border-radius: 50%;
            }
          }
        }
      }

      .overflowContainer {
        max-height: 200px;
        overflow-y: scroll;
        border: 1px solid #eeeeee;
        padding: 5px;
        border-radius: 8px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    .titleWrapper {
      display: flex;
      flex-direction: column;

      .filterButton {
        width: 100%;
        background: $--primary-orange;
        padding: 17px 0;
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 18px;
        color: white;
        font-weight: 600;
        min-height: 61px;
        max-height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
      }
    }
    .content {
      width: 100%;
    }
    .cardWrapper {
      grid-template-columns: 1fr;
    }

    .barWrapper {
      position: fixed;
      background: white;
      top: 0;
      left: 0;
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-right: unset;
      overflow-y: scroll;
      max-height: 100vh;

      .barTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        h4 {
          font-size: 24px;
          font-weight: 500;
        }
      }

      .filterBar {
        padding-right: 0;
        border: unset;
      }
    }
  }
}
