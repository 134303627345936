* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

body {
  /* background: linear-gradient(to right, #edf3f9, #fcecd0); */
  min-width: 250px;
  overflow-x: hidden;
}

.slick-list {
  margin: 0 -24px;
}
.slick-slide > div {
  padding: 0 24px;
}

.error-text {
  color: #b3261e;
  display: block;
  margin-top: -10px;
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

@media only screen and (max-width: 960px) {
  .slick-list {
    margin: 0 -10px;
  }
  .slick-slide > div {
    padding: 0 10px;
  }
}

/* .slick-slide {
  margin: 0 24px;
} */

/* the parent */
/* .slick-list {
  margin: 0 -24px;
} */

nav {
  position: sticky;
  top: 0;
  z-index: 99;
}

.nav-container {
  max-width: 1300px;
  margin: auto;
}

.menu-btn {
  display: none;
  outline: none;
  border: none;
  padding: 10px;
  font-size: 22px;
  cursor: pointer;
}

.overlay {
  display: none;
}

.nav-segment {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 15px #00000020;
  border-radius: 0 0 15px 15px;
  padding: 20px;
}

.nav-logo {
  transition: 0.3s ease all;
}

.nav-logo:hover {
  transform: scale(1.05);
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 25px;
  font-size: 14px;
}

.nav-link a {
  color: #393e42;
  text-decoration: none;
  font-weight: 600;
  padding: 15px;
  transition: 0.2s ease all;
  border: 1px solid transparent;
}

.nav-link a:hover {
  background: #00000010;
  border: 1px solid #00000005;
  border-radius: 6px;
}

.nav-link-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link-button a {
  padding: 15px 20px;
  border-radius: 12px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  transition: 0.2s ease all;
}

.nav-link-button a:hover {
  box-shadow: 0 0 15px #00000020;
}

.classic-btn {
  color: #393e42 !important;
  border: 1px solid #393e42;
}

.l-btn {
  background: #f59a12;
  border: 1px solid #f59a12;
  color: #fff;
  transition: 0.6s ease;
}
.l-btn:hover {
  background-color: transparent;
  color: #000;
}
.r-btn:hover {
  background-color: transparent;
  color: #000;
}

.r-btn {
  background: #306fdf;
  border: 1px solid #306fdf;
  color: #fff;
}

.l-btn-edited,
.r-btn-edited {
  width: 250px;
  text-align: center;
}

.r-btn-top {
  margin-bottom: -100px;
}

.container {
  max-width: 1250px;
  margin: auto;
}

.home-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.home-text {
  font-size: 58px;
  font-weight: 800;
  color: #383e42;
  transition: 0.5s ease;
}

.home-text:hover {
  will-change: transform;
  transform: translate3d(0px, 5.0848vh, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.home-text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 550px;
}

.home-text-alt {
  max-width: 500px;
  font-size: 18px;
  margin-top: 25px;
}

.home-img,
.home-img img {
  width: 430px;
}

.home-img img {
  box-shadow: -40px 24px 0px #fcecd1;
  border-radius: 220px 220px 170px 170px;
}

.nav-lang-op {
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  color: #242422;
}

.category-contianer form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.primary-btn-s2 {
  color: #fff;
  background: #306fdf;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  width: 120px;
  padding: 10px;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease all;
}
.category-contianer button:hover {
  background: #2156b0;
}

.nav-logo-m {
  display: none !important;
}

.home-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 42px !important;
  padding: 20px 0px 0px 0px;
  font-weight: 600;
}

.title-alt {
  text-align: center !important;
}

.flex-j {
  justify-content: center !important;
  text-align: center;
}

.hover-n:hover {
  box-shadow: 0 0 15px transparent !important;
}

.profile-btn {
  position: relative;
  border: 1px solid transparent !important;
}

.profile-btn:hover {
  box-shadow: 0 0 transparent !important;
}

.profile-btn img {
  position: absolute;
  top: 10px;
  left: -15px;
  border-radius: 50%;
}

.sup-box-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: nowrap;
  width: 1600px;
  margin: 30px 0;
}

.sup-overflow {
  overflow: hidden;
}

.sup-box {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  background: #fff;
  width: 140px;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 15px #00000010;
  transition: 0.3s ease all;
  height: 170px;
}

.sup-box:hover {
  cursor: pointer;
  box-shadow: 0 0 25px #00000020;
}

.s-box-img img {
  width: 80px;
}

.s-box-title {
  text-align: center;
}

.primary-btn-st {
  background: #306fdf;
  color: #fff;
  text-decoration: none;
  padding: 15px 35px;
  border-radius: 8px;
  transition: 0.3s ease all;
}

.primary-btn-st:hover {
  background: #2156b0;
}

.primary-btn-st i {
  margin-left: 10px;
}

.primary-btn {
  width: 100%;
  margin: auto;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #306fdf;
  color: #fff;
  text-decoration: none;
  padding: 15px 35px;
  border-radius: 8px;
  transition: 0.3s ease all;
}

.primary-btn i {
  margin-left: 10px;
}

.primary-btn:hover {
  background: #2156b0;
}

.features-link {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 10px;
  margin: 15px 0;
}

.features-link a {
  background: #fff;
  padding: 8px 20px;
  color: #383e42;
  box-shadow: 0 0 15px #00000010;
  border-radius: 8px;
  text-decoration: none;
  border: 1px solid #efefef;
  transition: 0.3s ease all;
  border: 1px solid #e5e5ea;
}

.features-link .active {
  color: #f59a12;
}

.features-link a:hover {
  box-shadow: 0 0 15px #00000030;
}

.s-card {
  display: flex;
  gap: 20px;
  background: #ffffff;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0 15px #00000020;
  text-decoration: none;
  color: #242422;
  transition: 0.3s ease all;
  border: 2px solid #fff;
}

.s-card:hover {
  background: #e7f3ff;
  border: 2px solid #3290ed;
}

.s-card-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.s-card-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-card-img img {
  width: 100px;
  border-radius: 20px;
}

.s-card-title {
  font-weight: 600;
  font-size: 18px;
}

.s-card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.s-card-cap {
  margin: 10px 0;
}

.s-card-info-star {
  font-weight: 600;
}

.s-card-info-loc {
  padding: 5px 10px;
  background: #d5eaff;
  color: #1666ef;
  border-radius: 10px;
}

.s-card-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  margin-top: 70px;
}

.relative {
  position: relative;
}
.fa-arrow-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.fa-info {
  color: #fff;
}
.see-all-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0px;
  background: linear-gradient(to top, #fff 10%, #ffffff20);
}

.bg-effect {
  padding-top: 50px;
  background: linear-gradient(to bottom, #fff, #ffffff20);
}

.box-f-container {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.box-f {
  background: #fff;
  width: 23%;
  padding: 20px;
  box-shadow: 0 0 15px #00000010;
  border-radius: 14px;
}

.box-f-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 70px;
  padding: 10px 0;
}

.p29 {
  padding: 15px 0;
}

.p14 {
  position: relative;
  padding: 15px;
}

.s-card a {
  text-decoration: none;
  color: #393e42;
}

.s-card-c {
  display: flex;
}

.s-card-c i {
  margin-right: 7px;
  color: #ff9a00;
}

.chat-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #ff9a00;
  border-radius: 15px;
}

.sy-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.sy-box {
  text-align: center;
  margin: 50px 0;
  width: 29%;
}

.happy-customer-container {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  height: 500px;
  border-radius: 15px !important;
  background: url(./assets/images/happycustomer.png);
  background-size: cover !important;
}

.footer-phone-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  height: 400px;
  border-radius: 15px !important;
  background: url(./assets/images/arkaplan.jpg) no-repeat center center;
  background-size: cover !important;
}

.h-text-header {
  font-size: 26px;
  font-weight: 800;
  padding-bottom: 10px;
}

.h-text-customer {
  height: 200px;
  width: 350px;
  background: #ffffff;
  color: #383e42;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px 10px 0 10px;
}

.h-customer-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f59a12;
  height: 350px;
  gap: 13px;
  padding: 15px;
  padding-top: 50px;
  border-radius: 10px;
  text-align: center;
}

.profile-p-slide-img {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%);
  background: rgb(255, 255, 255);
  width: 100px;
  height: 100px;
  padding: 4px;
  box-shadow: 0 0 15px #00000010;
  border-radius: 50%;
  overflow: hidden;
}

.profile-p-slide-img img {
  width: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
}

.profile-slide-text {
  color: #fff;
}

.profile-slide-text .italic {
  font-style: italic;
}

.profile-slide-text .icon-profile {
  margin: 20px 0;
}

.h-button-container {
  display: flex;
  align-items: center;
  justify-content: end;
}

.h-button-container-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  gap: 10px;
}

.h-buttons {
  box-shadow: 0 0 15px #00000020;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  border-radius: 0 0 11px 11px;
  height: 50px;
}

.h-buttons:nth-child(1) {
  background: #f59a12;
  color: #fff;
}

.h-buttons:nth-child(2) {
  color: #f59a12;
  background: #fef5e0;
}

.mySwiper {
  display: flex;
  max-width: 500px;
  height: 400px;
  margin: 0 15px !important;
  border-radius: 16px;
}

.swiper-slide {
  margin-top: 50px;
}

.sy-box a {
  color: #006edf;
  text-decoration: none;
}

.sy-box a i {
  margin-left: 4px;
}

.sy-box img {
  border-radius: 10px;
}

.sy-box-img {
  position: relative;
}

.sy-box-img-title {
  position: absolute;
  bottom: 40px;
  left: 20px;
  background: #fff;
  padding: 8px 14px;
  box-shadow: 0 0 15px #00000020;
  border-radius: 11px;
}

.footer-phone-bg {
  display: flex;
  align-items: center;
}

.footer-phone-img {
  display: flex;
  justify-content: end;
  width: 550px;
}

.footer-phone-img img {
  width: 350px;
}

.footer-phone-text {
  color: #fff;
  max-width: 400px;
}

.f-phone-text-header {
  font-size: 24px;
  font-weight: 800;
  padding: 20px 0;
}

.f-phone-alt {
  padding: 20px 0;
}

.f-phone-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.footer-container-width {
  background: #272f35;
  border-radius: 15px;
}

.footer-container {
  display: flex;

  flex-direction: column;
}

.footer-links-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  padding: 30px 0;
  width: 80%;
  margin: auto;
}

.footer-links a {
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-links-social {
  display: flex;
}

.footer-links-social a {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  width: 35px;
  border-radius: 50%;
  margin-left: 5px;
  height: 35px;
  border: 1px solid #ffffff37;
  color: #fff;
  transition: 0.3s ease all;
}

.footer-links-social a:hover {
  background: #ffffff;
  color: #242422;
}

.footer-kvk a {
  color: #8c9491 !important;
}

.border-line {
  border-top: 1px solid #3d4349;
}

.footer-section {
  gap: 30px;
  width: 80%;
  padding: 20px 0;
  padding-bottom: 50px;
  margin: auto;
}

.footer-box {
  position: relative;
  padding: 25px 45px;
  padding-bottom: 50px;
  border-radius: 12px;
}

.f-box-text-alt {
  color: #383e42;
  font-size: 14px;
}

.f-box-btn {
  position: absolute;
  left: 45px;
  bottom: -15px;
}

.f-box-btn a {
  padding: 12px 25px;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 0 0 15px #00000021;
  transition: 0.3s ease all;
}

.or-f a {
  background: #ff9a00;
}

.bl-f a {
  background: #006edf;
}

.or-f a:hover {
  background: #be7302;
}

.bl-f a:hover {
  background: #014d9a;
}

.footer-box:nth-child(1) {
  background: #fff5e0;
}

.footer-box:nth-child(2) {
  background: #dfefff;
}

.f-box-text {
  color: #383e42;
  font-size: 18px;
  font-weight: 800;
  padding: 10px 0;
}

.pop-up-links-container-sign {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.xmargk {
  cursor: pointer;
}

.pop-up-links-container-sign a {
  display: flex;
  align-items: end;
  justify-content: start;
  width: 100%;
  background: #fff5e6;
  height: 200px;
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 15px;
  transition: 0.3s ease all;
  border: 1px solid #cccccc00;
}

.pop-up-links-container-sign a:hover {
  background: #ffebcc;
  box-shadow: 0 0 15px #00000020;
  border: 1px solid #ccc;
}

.pop-up-links {
  padding: 15px;
}

.overlay-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #00000050;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.dl15 {
  display: flex;
}

.overlay-modal2 {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000050;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.overlay-modal3 {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000050;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.sign-text {
  font-weight: 800;
  font-size: 22px;
  padding: 20px 0;
}

.sign-column-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
  gap: 30px;
}

.sign-alt-text a {
  color: #f59a12;
  text-decoration: none;
}

.login-p,
.login-s {
  cursor: pointer;
}

.checkbox-container-s {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: start;
  text-align: left;
  margin-bottom: 20px;
}

.checkbox-container-s input {
  position: relative;
  box-shadow: 0 0 0 transparent !important;
  padding: 0;
  margin: 0;
}
.x-mark {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 22px;
  cursor: pointer;
  color: #383e42;
  transition: 0.6s ease;
}

.x-mark:hover {
  background-color: #f59a12;
  color: #fff;
}
.x-mark3 {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 22px;
  cursor: pointer;
  color: #383e42;
}

.modal-login {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 15px #00000020;
}

.modal-login3 {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 800px;

  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 15px #00000020;
}

.sign-text-alt {
  padding-bottom: 20px;
}

.sign-btn {
  cursor: pointer;
}

.modal3-btn {
  cursor: pointer;
}

.modal-login3 .sup-box-containers {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  gap: 5px;
  height: 500px;
  overflow: auto;
}

.modal-login2 {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px;

  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 15px #00000020;
}

.link-l li {
  display: flex;
  list-style-type: none;
  padding: 10px 0;
}

.link-l i {
  color: #006edf;
  margin-right: 5px;
}

.input-container {
  display: flex;
  justify-content: center;
  margin: auto;
  gap: 20px;
  flex-direction: column;
  width: 400px;
}

.pagination {
  list-style: none;
  padding: 8px;
  text-align: center;
  margin-top: 30px;
}

.pagination a,
.pagination li {
  border-radius: 5px;
  color: #212121;
  display: inline-block;
  margin: 0;
  transition: 0.15s ease-in;
  cursor: pointer;
  text-decoration: none;
  background: #f5f5f5;
}

.pagination a:hover {
  color: #fff;
  background: #006edf;
}

.pagination .pagination-number,
.pagination .pagination-arrow i {
  vertical-align: middle;
}

.pagination .pagination-number {
  font-family: sans-serif;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  width: 32px;
  padding: 4px 0;
}

.pagination .pagination-number.current-number {
  background: #006edf;
  color: #fff;
}

.pagination .pagination-arrow i {
  font-size: 24px;
  padding: 4px 8px;
}

.logo-align {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fcebcc;
  width: 200px;
  height: 200px;
}

.input-container input,
.input-container button {
  padding: 15px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #3676ff;
  box-shadow: 0 0 15px #00000020;
  transition: 0.3s ease all;
}

.input-container select {
  padding: 0px 15px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #3676ff;
  box-shadow: 0 0 15px #00000020;
  transition: 0.3s ease all;
}
.nav-links-button-sign {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-container input:focus {
  border: 1px solid #3676ff;
  background: #eff5ff;
}

.input-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease all;
  text-decoration: none;
}

.input-container button:hover {
  box-shadow: 0 0 5px #00000020;
}

.sign-in-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.sign-column {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 16px;
}

.sign-in-title {
  font-weight: 600;
  font-size: 26px;
}

.sign-in-title {
  margin-top: 50px;
}

.s2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;

  gap: 12px;
  padding: 25px 0;
}

.login-w-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 10px;
  border: 1px solid #3676ff;
  background: #fff;
  padding: 10px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  color: #222222;
  transition: 0.3s ease all;
  font-size: 14px;
  box-shadow: 0 0 15px #00000020;
}

.login-w-button:hover {
  background: #eef4ff;
  border: 1px solid #a3c1f5;
  box-shadow: 0 0 5px #00000020;
}

.product-filter-container {
  background: #fff;
  border-radius: 11px;
  margin-top: 40px;
  padding: 10px;
  box-shadow: 0 0 15px #00000020;
}

.product-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.product-filter a {
  width: 33%;
  border-radius: 11px;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  color: #383e42;
  transition: 0.3s ease all;
}

.product-filter a:hover {
  background: #e8f1fc;
}

.product-filter a span {
  font-weight: 800 !important;
}

.product-filter a:nth-child(2) {
  background: #e8f1fc;
}

.slice-text-c {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.slice-text {
  color: #000;
  background: #fff;
  padding: 10px;
}

.ilan-detay-container {
  background: #fff;
  box-shadow: 0 0 15px #00000020;
  border-radius: 13px;
  padding: 30px;
}

.ilan-detay-content {
  max-width: 1000px;
  margin: 35px auto;
}

.ilan-detay-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ilan-detay-text {
  font-weight: bold;
  font-size: 26px;
}

.ilan-detay-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
  margin: 20px 0;
}

.ilan-info-box {
  padding: 14px;
  border: 1px solid #8c9491;
  border-radius: 11px;
  width: 100%;
}

.ilan-detay-buttons {
  display: flex;
  font-size: 16px;
  gap: 20px;
  align-items: center;
}

.ilan-detay-buttons a {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 15px #00000020;
  border-radius: 8px;
  width: 160px;
  color: #383e42;
  text-decoration: none;
}

.ilan-detay-buttons a span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  border-radius: 8px;
}

.ilan-detay-buttons a:nth-child(1) span {
  background: #e21743;
}

.ilan-detay-buttons a:nth-child(2) span {
  background: #ff9a00;
}

.ilan-detay-buttons a span img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.ilan-detay-title {
  font-size: 18px;
  padding: 15px 0;
}

.ilan-detay-title span {
  font-weight: 600;
}

.uzman-profile-container {
  margin-top: 59px;
}

.uzman-profile-about-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.profile-header {
  padding: 20px 0;
  margin-top: 30px;
  font-size: 22px;
}

.container {
  padding: 0 10px;
}

li {
  list-style: none;
}

.rating-container {
  display: flex;
  gap: 20px;
  width: 300px;

  flex-direction: column;
}

.rating-score-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.score-c {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: #006edf;
  border-radius: 50%;
  font-weight: 600;
  font-size: 42px;
  color: #fff;
}

.score-detail div {
  font-weight: 600;
  margin-top: 6px;
  font-size: 22px;
}

.rating-container-column {
  display: flex;

  gap: 20px;
}

.rating-bars {
  width: 50%;
  float: left;
}

.rating-digit-holder {
  margin: 0 10px;
}

.rating-star-content {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  font-size: 18px;
}

.rating-star-content div {
  width: 150px !important;
}

.rating-bar-bg,
.rating-bar-bg .bar-position {
  height: 7px;
  border-radius: 100px;
}

.rating-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rating-bar-bg {
  position: relative;
  background: #d3d4d3;
  margin-top: 7px;
  margin-left: 7px;
}

.rating-bar-bg .colors-bar {
  background-color: #ff9a00;
}

.rating-bar-bg .bar-position {
  left: 0;
  position: absolute;
  width: 0;
}

.pagination-number {
  padding: 10px !important;
  background: #000;
}

.rating-total-numbers {
  font-size: 18px;

  font-weight: 600;
}

.comment-container {
  display: flex;
  gap: 40px;
}

.comment-container-card {
  background: #fff;
  border-radius: 15px;
  padding: 25px;
  max-width: 31%;
  box-shadow: 0 0 15px #00000020;
}

.comment-card-header {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin-bottom: 10px;
}

.profile-name span {
  color: #006edf;
}

.comment-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.account-page {
  display: flex;
  gap: 30px;
}

.sidebar-container {
  position: sticky;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 15px #00000020;
}

.content-section {
  width: 100%;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 15px #00000020;
}

.sidebar-profile-section {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
}

.side-profile-title {
  font-weight: 600;
}

.side-profile-title a {
  color: #006edf;
  font-size: 14px;
  text-decoration: none;
}

.sidebar-profile-section img {
  border-radius: 50%;
}

.sidebar-links-container {
  display: flex;
  flex-direction: column;
}

.sidebar-links-container a {
  display: flex;
  align-items: center;
  color: #212121;
  border-left: 2px solid transparent;
  border-bottom: 1px solid #efecf3;
  gap: 15px;
  padding: 15px 20px;
  text-decoration: none;
  transition: 0.3s ease all;
}

.sidebar-links-container button {
  display: flex;
  align-items: center;
  color: #212121;
  border-left: 2px solid transparent;
  gap: 15px;
  padding: 20px;
  text-decoration: none;
  transition: 0.3s ease all;
}
.active-sidebar,
.sidebar-links-container a:hover {
  color: #ff9a00 !important;
  border-left: 2px solid #ff9a00 !important;
}

.active-sidebar,
.sidebar-links-container button:hover {
  color: #ff9a00 !important;
  border-left: 2px solid #ff9a00 !important;
}

.account-content-container {
  padding: 30px;
}

.acc-title {
  font-size: 26px;
  margin-bottom: 25px;
  font-weight: 800;
  color: #383e42;
}

.progress-bar-acc {
  display: flex;
  border-radius: 5px;
  background: #efecf3;
  height: 5px;
  margin-top: 10px;
}

.progress-bar-in {
  background: #006edf;
  border-radius: 5px;
}

.acc-photo-up {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  height: 150px;
  text-align: center;
  border: 2px dotted #efecf3;
  border-radius: 15px;
  cursor: pointer;
}

.acc-content-flex {
  margin-top: 30px;
  display: flex;
  gap: 20px;
}

.account-content-accordion-list {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #efecf3;
}
.account-content-accordion-list2 {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #efecf3;
}

.acc-delete {
  margin-top: 50px;
}

.acc-delete {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  text-decoration: none;
  color: #e21743;
}

.questions-header {
  padding: 50px;
  font-size: 48px;
  text-align: center;
}

.asked-questions-container {
  max-width: 1100px;
  margin: auto;
}

.faq-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  color: #303030;
  transition: 0.2s ease all;
  cursor: pointer;
}

.borderLine {
  border-bottom: 1px solid #efecf3;
}

.faq-header:hover {
  color: #1666ef;
}

.faq-active .faq-header {
  color: #1666ef;
}

.acc-a-title {
  font-weight: 600;
}

.acc-a-title span {
  font-weight: 400 !important;
}

.paragraph {
  transition: 1s all;
  color: #585151;
  font-size: 20px;
}

.acc-in {
  padding: 5px;
  border: 1px solid #efecf3;
  border-radius: 8px;
  width: 100%;
  outline: none;
}

.accordion-item .paragraph {
  transition: 0.3s all;
}

.faq-active .paragraph {
  height: 100%;
  max-height: 500px;
}

.accordion-menu {
  max-width: 800px;
  margin: auto;
}

.paragraph-content {
  padding: 22px;
}

.input-container-top {
  display: flex;
  flex-direction: column;
}

.input-alt {
  display: flex;
  flex-direction: column !important;
}

.input-container-top {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: row;
}

.input-delete-column {
  display: flex;
  flex-direction: column;
}

.sifre-text {
  padding: 15px 0;
  color: #383e42;
  font-weight: 800;
}

.account-content-title {
  margin-bottom: 30px;
}

.input-delete-column span {
  color: #e21743;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.input-container-alt {
  display: flex;
  gap: 10px;
  justify-content: start;
}

.sifre-input-container {
  display: flex;
  flex-direction: column;
  gap: 22px;
  border-bottom: 1px solid #e5e5ea;
  width: 100%;
  padding-bottom: 20px;
}

.password-login {
  width: 100%;
}

.pass-content {
  display: flex;
  align-items: start;
}

.sifre-input-container input {
  padding: 10px;
  outline: 0;
  border: 1px solid #d5d2dc;
  border-radius: 8px;
  margin: 10px 0;
  width: 300px;
}

.sifre-input-container input:focus {
  border: 1px solid #003cff;
}

.last-sign-table {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5ea;
  padding: 20px 0;
}

.last-s-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px #00000030;
  cursor: pointer;
}

.last-s-btn:hover {
  color: #be7302;
}

.last-s-btn-hover {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 0 10px #00000030;
  border-radius: 5px;
  width: 150px;
  height: auto;
  padding: 10px 0;
  transform: scale(0);
  transition: 0.3s ease all;
  transform-origin: top right;
  z-index: 5;
}

.last-s-btn:hover .last-s-btn-hover {
  transform: scale(1);
}

.last-s-btn-hover a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #383e42;
  padding: 6px 10px;
}

.radio-button-container {
  display: flex;
  flex-direction: column;
}

.radio-group {
  display: flex;
  padding: 10px 0;
  gap: 10px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

input[type="checkbox"] {
  accent-color: orange;
  font-size: 22px;
  width: 20px;
  height: 20px;
}

.setting-save-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  padding: 25px;
}

.setting-save-container a {
  padding: 10px 15px;
  background: #383e42;
  text-decoration: none;
  border-radius: 8px;
}

.setting-save-container a:nth-child(1) {
  border: 1px solid #e5e5ea;
  color: #383e42;
  background: #fff;
}

.setting-save-container a:nth-child(2) {
  border: 1px solid #006edf;
  color: #fff;
  background: #006edf;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22.6px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17.3px;
  width: 17.3px;
  left: 2.6px;
  bottom: 2.6px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.radio-button-container input:checked + .slider {
  background-color: #2196f3;
}

.radio-button-container input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.radio-button-container input:checked + .slider:before {
  -webkit-transform: translateX(17.3px);
  -ms-transform: translateX(17.3px);
  transform: translateX(17.3px);
}

.switch-container {
  display: flex;
  gap: 20px;
  padding: 14px 0;
}

.switch-title span {
  font-weight: 800;
}

.h-pro {
  background: #fff !important;
  color: #006edf !important;
  padding: 10px !important;
  box-shadow: 0 0 5px #00000030;
}

.h-pro2 {
  background: #f5f5f5 !important;
  color: #383e4250 !important;
  padding: 10px !important;
}

.ps20 {
  display: flex;
  flex-direction: column;
}

.old-btn {
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  padding: 12px;
  background: #fff;
  box-shadow: 0 0 10px #00000020;
  text-align: end;
  border-radius: 11px;
  color: #383e42;
}

.old-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff9a00;
  background: #fff0d9;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 8px;
}

.old-btn-icon2 {
  position: absolute;
  top: -20px;
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #006edf;
  background: #cfe7ff;
  width: 40px;
  font-size: 18px;
  height: 40px;
  border-radius: 8px;
}

.old-btn-text {
  padding: 12px;
}

.old-button-container {
  display: flex;
  gap: 20px;
}

.message-box-title {
  font-weight: 800;
  color: #383e42;
}

.message-box-search-bar {
  display: flex;
  align-items: center;
  color: #ff9a00;
  border: 1px solid #d3d4d3;
  border-radius: 15px;
  padding: 0 15px;
  margin: 20px 0;
}

.message-box-search-bar input {
  padding: 15px;
  outline: none;
  border: none;
}

.message-box-contact {
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
}

.message-box-sidebar {
  width: 230px;
}

.message-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  border-radius: 15px;
}

.message-contact-profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.message-contact-profile img {
  border-radius: 50%;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ff9901;
  color: #fff;
  padding: 4px;
  width: 20px;
  height: 20px;
  font-size: 11px;
}

.message-box-flex-container {
  display: flex;
  gap: 20px;
}

.message-box-content {
  width: 80%;
}

.message-box-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #383e4260;
  background-color: white;
}

.title {
  font-weight: 800;
  color: #383e42;
}

.title span {
  font-size: 14px;
  font-weight: 400;
}

.title span i {
  font-size: 8px;
  color: #006edf;
  margin-right: 5px;
}

.dot-line {
  font-size: 22px;
  color: red;
}
.dot-line-report {
  color: #585151;
}

.message-column {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.message-column:nth-of-type(odd) {
  justify-content: end;
}

.m-text {
  width: 250px;
  background: #ff9a00;
  color: #fff;
  padding: 13px 20px;
  border-radius: 11px;
}

.message-column:nth-child(even) .m-text {
  background: #383e42 !important;
  color: #fff;
}

.column-faces {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
  color: #252525c9;
}

.message-column:nth-of-type(odd) .column-faces-date {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: end;
}

.column-faces-date i {
  color: #1666ef;
}

.loader {
  width: 20px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #252525c9 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}

.loader-text {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #888b8e;
}

@keyframes l7 {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }

  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }

  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}

.message-box-input-container {
  display: flex;
  flex-direction: column;
}

.message-box-input-container textarea {
  width: 100%;
  height: 100px;
  resize: none;
  background: #ececed;
  outline: none;
  border-radius: 11px 11px 0 0;
  border: none;
  padding: 20px;
  font-size: 16px;
}

.message-box-input-container textarea::placeholder {
  font-size: 16px;
}

.submit-textarea-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ececed;
  padding: 20px;
  border-radius: 0 0 11px 11px;
}

.submit-textarea button {
  outline: none;
  border: none;
  background: #006edf;
  color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
}

.icons-textarea {
  display: flex;
  align-items: center;
  gap: 13px;
  font-size: 18px;
  color: #c1c3c4;
}

.icons-textarea i {
  cursor: pointer;
}

.swiper {
  width: 100%;
  height: 100%;
}

slide-form .swiper-slide {
  background-position: center;
  background-size: cover;
  background: #fff;
}

slide-form .swiper-slide img {
  display: block;
  width: 100%;
}

.slide-item-container {
  background: #fff;
  padding: 30px 20px;
  border-radius: 15px 15px 0 0;
  height: 600px;
  overflow: auto;
}

.slide-item-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-size: 22px;
}

.slide-item-nav .progress-bar-acc {
  width: 95%;
}

.slide-item-title {
  font-weight: 800;
  color: #383e42;
  font-size: 22px;
  padding: 25px;
}

.slide-item-buttons {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.slide-item-buttons button {
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: left;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  background: #ecebe9;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.3s ease all;
  font-size: 16px;
  font-weight: 600;
}

.slide-item-buttons button:focus {
  background: #006edf;
  color: #fff;
}

.checkbox-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.slide-check {
  background: #fff;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}

.slide-item-button:focus .slide-check,
.slide-item-button.selected .slide-check {
  background: #ff9a00;
}

.slide-item-button.selected .slide-check {
  background: #ff9a00;
}

.slide-item-button {
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}

.slide-item-button:hover .checkbox-slide {
  background: #f0f0f0; /* Change background on hover */
}

.slide-items-paginations {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center;
  padding: 20px;
  border-radius: 0 0 15px 15px;
}

.slide-items-paginations button {
  padding: 15px 25px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease all;
  font-weight: 600;
}

.swiper-button-next-e {
  background: #ff9a00;
  color: #fff;
}

.swiper-button-next-e:hover {
  transform: scale(1.05);
}

.slide-items-textarea textarea {
  width: 100%;
  height: 100px;
  resize: none;
  background: #fff;
  outline: none;
  border-radius: 11px;
  border: none;
  padding: 20px;
  height: 300px;
  font-size: 16px;
  border: 2px solid #ebe5d9;
}

.slide-items-inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.slide-items-inputs select {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: #f1f1f1;
  outline: none;
  border: 2px solid #003cff00;
  font-size: 16px;
  transition: 0.3s ease all;
}

.slide-items-inputs input {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: #f1f1f1;
  outline: none;
  border: 2px solid #003cff00;
  font-size: 16px;
  transition: 0.3s ease all;
}

.slide-items-inputs input:focus {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: #f1f1f1;
  outline: none;
  background: #fff;
  border: 2px solid #003cff;
  font-size: 16px;
}

.planned-text {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
}

.planned-btn {
  display: flex;
  align-items: start !important;
  justify-content: start !important;
  flex-direction: column !important;
  width: 100%;
}

.planned-btn input {
  padding: 10px;
  border-radius: 11px;
  outline: none;
  background: #fff;
  outline: none;
  border: 2px solid transparent;
  font-size: 16px;
  width: 100%;
}

.planned-btn input:focus {
  border: 2px solid #003cff;
}

.slide-done-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.slide-done-text {
  display: flex;
  gap: 0px;
  flex-direction: column;
}

.about-us-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.about-us-flex-container {
  display: flex;
  align-items: center;
  gap: 30px;
  font-weight: 600;
  margin-top: 50px;
  color: #383e42;
}

.about-us-text-top {
  color: #006edf;
  font-size: 18px;
}

.about-us-text-header {
  font-size: 38px;
}

.border-o-short {
  height: 4px;
  background: #ff9a00;
  width: 50px;
  border-radius: 15px;
}

.about-box-container {
  background: #fff5e6;
  padding: 65px 25px;
  border-radius: 15px;
}

.about-boxes-container {
  display: grid;
  grid-template-columns: 3;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.boxes-about-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background: #ff9a00;
}

.boxes-about {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px;
  width: 25%;
  border-radius: 15px;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  transition: 0.3s ease all;
}

.p12322q {
  flex-wrap: wrap;
}

.p12322 {
  width: 32%;
  gap: 10px;
}

.boxes-about:hover {
  background: #fff;
  box-shadow: 0 0 15px #00000020;
}

.boxes-about-title {
  font-size: 18px;
}

.boxes-about-alt {
  height: auto;
}

.alt-box-container-about {
  gap: 0;
  box-shadow: 0 0 15px #00000020;
  border-radius: 15px !important;
  overflow: hidden;
}

.alt-box-container-about .boxes-about {
  background: #fff;
  border-radius: 0;
}

.alt-box-container-about .boxes-about-title {
  font-size: 28px;
  font-weight: 800;
}

.frameembed {
  height: 500px;
  margin-top: 20px;
  margin-bottom: 250px;
}

.bacground-contact {
  position: absolute;
  top: 0;
  background: "";
  height: 500px;
  width: 100%;
  background: url("./assets/images/harita.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  z-index: -1;
}

.bacground-contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #fff9eb50, #fff9eb);
}

.contact-container-icon-link {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
}

.icon-contact-p {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff9a00;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.c-form-w {
  width: 80%;
}

.contact-form-c {
  position: relative;
  top: -150px;
  background: #fff;
  width: 100%;
  padding: 25px 20px;
  border-radius: 15px;
  box-shadow: 0 0 15px #00000020;
}

.c-form-title {
  font-weight: 800;
  font-size: 18px;
}

.input-c-form-f {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.input-c-form-f input {
  width: 50%;
  padding: 10px;
  color: #242422;
  font-weight: bold;
  border: 1px solid #e5e5ea;
  outline: none;
  transition: 0.3s ease all;
  border-radius: 8px;
}

.input-c-form-textarea textarea {
  width: 100%;
  resize: none;
  width: 100%;
  padding: 10px;
  color: #242422;
  font-weight: bold;
  border: 1px solid #e5e5ea;
  outline: none;
  transition: 0.3s ease all;
  border-radius: 8px;
}

.input-c-form-f input:focus,
.input-c-form-textarea textarea:focus {
  border: 1px solid #1666ef;
}

.help-search-container {
  background: "";
  background-repeat: no-repeat;
  background-position: 80%;
  color: #fff;
  padding: 20px;
  margin-top: 50px;
  border-radius: 15px;
}
.help-search-input-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  color: #4e4e4e;
  max-width: 500px;
  margin: 25px 0;
}

.help-search-input-container input {
  padding: 15px;
  width: 100%;
  outline: none;
  border: none;
  color: #000;
  font-weight: 600;
}

.help-search-input-container i {
  padding-left: 10px;
  font-size: 18px;
}
