@import "../../assets/style/variable.module.scss";

.wrapper {
  width: 100vw;
  position: fixed;
  top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.header {
  background: rgba($color: white, $alpha: 0.7);
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px;
  border: 1px solid #eeeeee;
  border-radius: 100px;
  backdrop-filter: blur(8px);
  box-shadow: 0px 0px 40px 0px rgba($color: #000000, $alpha: 0.05);

  .logo {
    height: 40px;
    top: 2px;
    position: relative;

    img {
      height: 100%;
    }
  }

  .buttonList {
    display: flex;
    gap: 12px;

    li {
      a {
        font-weight: 700;
        padding: 11px 16px;
        box-sizing: border-box;
        border-radius: 8px;
      }

      .primary {
        background: $--primary-blue;
        color: white;
        border: 1px solid #699fff;
      }
      .secondary {
        background: $--primary-orange;
        color: white;
        border: 1px solid #ffbc59;
      }
      .third {
        background: transparent;
        color: #646464;
        border: 1px solid #e3e3e3;
      }
    }
  }
}

.mobileMenu {
  position: absolute;
  width: calc(100% - 40px);
  background: rgba($color: white, $alpha: 0.7);
  top: 90px;
  border-radius: 30px;
  box-shadow: 0px 0px 40px 0px rgba($color: #000000, $alpha: 0.05);
  backdrop-filter: blur(8px);
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;

  .buttonList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 25px;

    li {
      width: 100%;
      display: flex;
      align-items: center;
      a {
        font-weight: 700;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100% !important;
        text-align: center;
        padding: 10px 0;
      }

      .primary {
        background: $--primary-blue;
        color: white;
        border: 1px solid #699fff;
      }
      .secondary {
        background: $--primary-orange;
        color: white;
        border: 1px solid #ffbc59;
      }
      .third {
        background: transparent;
        color: #646464;
        border: 1px solid #e3e3e3;
        background: white;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .header {
    max-width: 900px;
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    padding: 0 20px;
    box-sizing: border-box;
    .header {
      padding: 20px;

      .hamburgerButton {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
