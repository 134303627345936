@import "../../assets/style/variable.module.scss";

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 150px;

  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;

    .card {
      border: 1px solid #d3d3d3;
      position: relative;
      border-radius: 50px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background: red;
        z-index: 1;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.5s ease;

        p {
          position: absolute;
          bottom: 20px;
          z-index: 2;
          font-size: 30px;
          text-align: center;
          max-width: 80%;
          font-weight: 700;
          color: white;
        }
      }

      &:hover {
        .cover {
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    max-width: 900px;
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    margin-top: 75px;
    padding: 0 20px;
    box-sizing: border-box;

    .cardWrapper {
      grid-template-columns: 1fr 1fr;

      .card {
        border-radius: 25px;

        .cover {
          p {
            font-size: 22px;
          }
        }
      }
    }
  }
}
